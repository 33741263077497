<template>
  <c-flex
    id="bottomNavigation"
    as="nav"
    pos="fixed"
    left="0"
    bottom="0px"
    h="64px"
    w="100vw"
    bg="white"
    border-top="0.5px solid #C4C4C4"
    align-items="center"
    justify-content="space-around"
    box-shadow="4px -4px 50px 5px rgba(0, 0, 0, 0.15)"
    :display="['flex', 'none']"
    py="10px"
    px="4px"
    z-index="11"
  >
    <portal
      v-if="isMenuActive"
      to="main"
    >
      <modal-menu z-index="1000" />
    </portal>
    <template v-for="menu in menus">
      <c-flex
        v-if="menu.route == null"
        :key="menu.name"
      >
        <c-button
          variant="ghost"
          p="0"
          m="0"
          flex-direction="column"
          justify-content="center"
          align-items="center"
          @click="onMenuClicked(menu)"
        >
          <c-image
            class="icon"
            :alt="menu.name"
            :src="menu.icon"
            w="24px"
            h="24px"
          />
          <c-text
            v-chakra="{
              '&.active': {
                color: 'primary.400',
              },
            }"
            :class="{ active: menu.isActive }"
            font-size="12px"
            font-weight="400"
            font-family="Roboto"
            color="#555555"
          >
            {{ menu.name }}
          </c-text>
        </c-button>
      </c-flex>
      <c-link
        v-if="menu.route != null"
        :key="menu.name"
        as="router-link"
        :to="menu.route"
        class="menu-item"
        exact-active-class="active"
        @click="onMenuClicked(menu)"
      >
        <c-flex
          flex-direction="column"
          justify-content="center"
          align-items="center"
        >
          <c-image
            class="icon"
            :alt="menu.name"
            :src="menu.icon"
            w="24px"
            h="24px"
          />
          <c-text
            v-chakra="{
              '&.active': {
                color: 'primary.400',
              },
            }"
            :class="{ active: menu.isActive }"
            font-size="12px"
            font-weight="400"
            color="#555555"
            font-family="Roboto"
          >
            {{ menu.name }}
          </c-text>
        </c-flex>
      </c-link>
    </template>
  </c-flex>
</template>

<script>
import { mapState } from 'vuex'
import ModalMenu from '@/components/modals/modal-menu.vue'

export default {
  name: 'NavBottom',
  components: {
    ModalMenu,
  },
  data() {
    return {
      isMenuActive: false,
    }
  },
  computed: {
    ...mapState({
      user: (s) => s.auth.user,
      role: (s) => s.auth.user?.role,
    }),
    menus() {
      const self = this
      if (self.role === 'client') {
        return [
          {
            name: 'Beranda',
            route: '/',
            get icon() {
              if (this.isActive) {
                return require('@/assets/icons/icon-home-active.svg')
              } else {
                return require('@/assets/icons/icon-home-grey.svg')
              }
            },
            get isActive() {
              return !self.isMenuActive && self.$route.path === '/'
            },
          },
          {
            name: 'Pemantauan',
            route: { name: 'under-maintenance' },
            // route: '/progress-tracker',
            get icon() {
              if (this.isActive) {
                return require('@/assets/icons/icon-monitor-active.svg')
              }
              return require('@/assets/icons/icon-monitor-grey.svg')
            },
            get isActive() {
              return !self.isMenuActive && self.$route.path === 'client.progress'
            },
          },
          {
            name: 'Panduan Diet',
            route: { name: 'client.content-challenge' },
            get icon() {
              if (this.isActive)
                return require('@/assets/icons/icon-coach-active.svg')
              return require('@/assets/icons/icon-coach-grey.svg')
            },
            get isActive() {
              return !self.isMenuActive && self.$route.name.includes('client.content-challenge')
            },
          },
          {
            name: 'Profile',
            route: '/profile',
            get icon() {
              if (this.isActive)
                return require('@/assets/icons/icon-account-circle-active.svg')
              return require('@/assets/icons/icon-account-circle-grey.svg')
            },
            get isActive() {
              return (
                !self.isMenuActive && self.$route.name === 'client.profile'
              )
            },
          },
        ]
      }

      return [
        {
          name: 'Beranda',
          route: '/management-client',
          get icon() {
            if (this.isActive) {
              return require('@/assets/icon-home-active.svg')
            } else {
              return require('@/assets/icon-home-grey.svg')
            }
          },
          get isActive() {
            return (
              !self.isMenuActive && self.$route.path === '/management-client'
            )
          },
        },
        {
          name: 'Coaching Course',
          route: '/content-challenge',
          get icon() {
            if (this.isActive) {
              return require('@/assets/icon-target-active.svg')
            }
            return require('@/assets/icon-target.svg')
          },
          get isActive() {
            return (
              !self.isMenuActive && self.$route.path === '/content-challenge'
            )
          },
        },
        {
          name: 'Profile',
          route: '/profile',
          get icon() {
            if (this.isActive) return require('@/assets/icon-user-active.svg')
            return require('@/assets/icon-user-grey.svg')
          },
          get isActive() {
            return !self.isMenuActive && self.$route.name === 'client.profile'
          },
        },
      ]
    },
  },
  methods: {
    onMenuClicked(menu) {
      if (menu.name === 'Menu') {
        this.isMenuActive = !this.isMenuActive
      }
    },
  },
}
</script>
