<template>
  <c-flex
    :d="['none', 'flex']"
    my="1rem"
    mx="0"
    flex-shrink="0"
    pos="sticky"
    top="100px"
    h="fit-content"
    transition-duration="300ms"
    flex-grow="1"
  >
    <c-flex
      flex-direction="column"
      pr="16px"
      w="100%"
    >
      <template v-for="menu in sideMenu">
        <c-button
          v-if="menu.route != null"
          :key="menu.name"
          variant="ghost"
          as="router-link"
          :to="menu.route"
          d="flex"
          flex-direction="row"
          align-items="center"
          justify-content="start"
          mb="16px"
          px="8px"
          :background="open ? (menu.isActive ? '#F2F2F2' : 'transparent') : '#F2F2F2'"
          :border-left="open ? (menu.isActive ? '8px solid #008C81' : '8px solid transparent') : (menu.isActive ? '8px solid #008C81' : '8px solid #F2F2F2')"
          border-radius="8px"
          h="60px"
        >
          <c-image 
            class="icon"
            :alt="menu.name"
            :src="menu.icon"
            w="24px"
            h="24px"
          />
          <c-text
            v-if="open"
            font-size="16px"
            font-weight="400"
            font-family="Roboto"
            :color="menu.isActive ? '#008C81' : '#555555'"
            ml="12px"
          >
            {{ menu.name }}
          </c-text>
        </c-button>
        <c-flex
          v-else
          :key="menu.name"
          flex-direction="column"
          w="100%"
        >
          <c-button
            variant="ghost"
            d="flex"
            flex-direction="row"
            align-items="center"
            justify-content="start"
            px="8px"
            :background="open ? (menu.isActive ? '#F2F2F2' : 'transparent') : '#F2F2F2'"
            :border-left="open ? (menu.isActive ? '8px solid #008C81' : '8px solid transparent') : (menu.isActive ? '8px solid #008C81' : '8px solid #F2F2F2')"
            border-radius="8px"
            h="60px"
            @click="toggleOpenProfile()"
          >
            <c-image 
              class="icon"
              :alt="menu.name"
              :src="menu.icon"
              w="24px"
              h="24px"
            />
            <c-text
              v-if="open"
              font-size="16px"
              font-weight="400"
              font-family="Roboto"
              :color="menu.isActive ? '#008C81' : '#555555'"
              ml="12px"
            >
              {{ menu.name }}
            </c-text>
          </c-button>
          <c-button-group
            :d="open ? (isOpenProfile ? 'flex' : 'none') : 'none'"
            flex-direction="column"
            mt="8px"
            mx="auto"
            w="100%"
            px="16px"
          >
            <c-button 
              v-for="child in menu.children" 
              :key="child.name" 
              as="router-link"
              :to="child.route"
              variant="ghost"
              mx="auto"
              d="flex"
              w="100%"
              px="16px"
              flex-direction="row"
              align-items="center"
              justify-content="start"
              :border-bottom="child.isActive ? '1px solid #008C81' : '1px solid #C4C4C4'"
              border-radius="0px"
              mb="8px"
            >
              <c-text
                font-size="16px"
                :color="child.isActive ? '#008C81' : '#888888'"
                font-family="Roboto"
                font-weight="400"
              >
                {{ child.name }}
              </c-text>
            </c-button>
          </c-button-group> 
        </c-flex>
      </template>
    </c-flex>
  </c-flex>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SideMenu',
  props: {
    open: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpenProfile: false,
    }
  },
  computed: {
    ...mapState({
      user: (s) => s.auth.user,
      role: (s) => s.auth.user?.role,
    }),
    sideMenu() {
      const self = this
      return [
        {
          name: 'Manajemen Klien',
          route: '/management-client',
          get icon() {
            if (this.isActive) {
              return require('@/assets/icons/icon-supervisor-account.svg')
            } else {
              return require('@/assets/icons/icon-supervisor-account-disabled.svg')
            }
          },
          get isActive() {
            return self.$route.name === 'nutrisionist.managementClient'
          },
        },
        {
          name: 'Panduan Diet',
          route: '/content-challenge',
          get icon() {
            if (this.isActive) {
              return require('@/assets/icons/icon-coach-active.svg')
            } else {
              return require('@/assets/icons/icon-coach-grey.svg')
            }
          },
          get isActive() {
            return self.$route?.name?.includes('content-challenge')
          },
        },
        {
          name: 'Nilai Gizi',
          route: '/glossaries',
          get icon() {
            if (this.isActive) {
              return require('@/assets/icon-box-active.svg')
            } else {
              return require('@/assets/icon-box.svg')
            }
          },
          get isActive() {
            return self.$route?.name?.includes('glossaries')
          },
        },
        {
          name: 'Profil',
          route: '/profile/account',
          get icon() {
            if (this.isActive) {
              return require('@/assets/icons/icon-account-circle-active.svg')
            } else {
              return require('@/assets/icons/icon-account-circle-grey.svg')
            }
          },
          get isActive() {
            return self.$route?.name?.includes('client.profile.account')
          },
        },
      ]
    },
  },
  methods: {
    toggleOpenProfile() {
      this.$router.push({
        name: 'client.profile',
      }).catch(()=>{})
      if (!this.open) {
        this.$emit('updateOpen', true)
        this.isOpenProfile = true
      } else {
        this.isOpenProfile = !this.isOpenProfile
      }
    },
  },
}
</script>
